import React from "react";
import { ParallaxBanner } from "../components/ParrallaxBanner.js";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useMediaQuery } from "react-responsive";

// Components
import { Objectives } from "../components/Objectives.js";
import { Footer } from "../components/Footer.js";
import { Media } from "../components/Media.js";

export const Home = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const objectiveOffset = 1.5;
  const offsetAfterObjective = isTabletOrMobile ? 5.5 : 4.5;

  const BANNER_HEIGHT = 1;
  const MEDIA_HEIGHT = 0.5;
  const OBJECTIVES_HEIGHT = isTabletOrMobile ? 4 : 3;
  const FOOTER_HEIGHT = 0.05;

  const PAGES =
    BANNER_HEIGHT + MEDIA_HEIGHT + OBJECTIVES_HEIGHT + FOOTER_HEIGHT;
  console.log(PAGES);

  return (
    <Parallax pages={PAGES} style={{ top: "0", left: "0" }}>
      <ParallaxBanner />
      <ParallaxLayer offset={1} speed={0}>
        <Media />
      </ParallaxLayer>
      <ParallaxLayer offset={objectiveOffset} speed={0}>
        <Objectives />
      </ParallaxLayer>
      <Footer offset={offsetAfterObjective} />
    </Parallax>
  );
};

export default Home;
