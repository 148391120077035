import React from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import styles from "../styles/Footer.module.css";

export const Footer = ({ offset }) => {
  return (
    <ParallaxLayer offset={offset}>
      <div className={styles.Footer}>
        <div>
          All digital photographs of the paintings are provided and rightfully
          owned by the Fernando C. Amorsolo Art Foundation Inc.
        </div>
      </div>
    </ParallaxLayer>
  );
};

export default Footer;
