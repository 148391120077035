import React from "react";
import { useMediaQuery } from "react-responsive";

// Import Icons
import { Icon } from "./Icons";

// Parallax Package
import { ParallaxLayer } from "@react-spring/parallax";

// Styles
import styles from "../styles/ParallaxBanner.module.css";

// Assets
import BGFarmers from "../assets/Farmers.webp";
import BGFarmersMobile from "../assets/FarmersMobile.webp";
import BGFarm from "../assets/Farm.webp";
import BGFarmMobile from "../assets/FarmMobile.webp";
import BGHut from "../assets/Hut.webp";
import BGHutMobile from "../assets/HutMobile.webp";
import BGCarabao from "../assets/Carabao.webp";
import BGHills from "../assets/Hills.webp";
import BGHillsMobile from "../assets/HillsMobile.webp";
import BGClouds from "../assets/Clouds.webp";
import BGCloudsMobile from "../assets/CloudsMobile.webp";
import BGSky from "../assets/Sky.webp";
import BGSkyMobile from "../assets/SkyMobile.webp";

import Title from "../assets/San_Fernando_Logo.png";

export const ParallaxBanner = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <ParallaxLayer
        factor={3}
        speed={0}
        style={{
          backgroundSize: "cover",
          backgroundImage: isTabletOrMobile
            ? `url(${BGSkyMobile})`
            : `url(${BGSky})`,
          backgroundPosition: "center",
        }}
      />
      <ParallaxLayer
        speed={0.25}
        style={{
          backgroundSize: "cover",
          backgroundImage: isTabletOrMobile
            ? `url(${BGCloudsMobile})`
            : `url(${BGClouds})`,
          backgroundPosition: "center",
        }}
      />
      <ParallaxLayer
        speed={0.5}
        style={{
          backgroundSize: "cover",
          backgroundImage: isTabletOrMobile
            ? `url(${BGHillsMobile})`
            : `url(${BGHills})`,
          backgroundPosition: "center",
        }}
      ></ParallaxLayer>
      <ParallaxLayer
        speed={0.4}
        style={{
          backgroundSize: "cover",
          backgroundImage: isTabletOrMobile
            ? `url(${BGHutMobile})`
            : `url(${BGHut})`,
          backgroundPosition: "center",
        }}
      />
      <ParallaxLayer
        speed={0.4}
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${BGCarabao})`,
          backgroundPosition: "center",
        }}
      />
      <ParallaxLayer
        speed={0}
        style={{
          backgroundSize: "cover",
          backgroundImage: isTabletOrMobile
            ? `url(${BGFarmMobile})`
            : `url(${BGFarm})`,
          backgroundPosition: "center",
        }}
      ></ParallaxLayer>
      <ParallaxLayer
        speed={0}
        style={{
          backgroundSize: "cover",
          backgroundImage: isTabletOrMobile
            ? `url(${BGFarmersMobile})`
            : `url(${BGFarmers})`,
          backgroundPosition: "center",
        }}
      />
      <ParallaxLayer speed={-0.2}>
        <div className={styles.title_container}>
          <div className={styles.title}>
            <img src={Title} />
          </div>
          <div className={styles.subtitle}>
            San Fernando is a picturesque rural town with plenty of yet
            undiscovered potential, and it is up to you to reveal it.
          </div>
          <div className={styles.titleWishlistButton}>
            <Icon name="steam" label="ADD TO WISHLIST" />
          </div>
        </div>
      </ParallaxLayer>
    </>
  );
};

export default ParallaxBanner;
