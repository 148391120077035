import React from "react";
import styles from "../styles/Media.module.css";

export const Media = () => {
  return (
    <div className={styles.Video}>
      <iframe
        height="80%"
        src="https://www.youtube.com/embed/h3qPw_YaLoI"
      ></iframe>
    </div>
  );
};

export default Media;
