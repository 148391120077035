import React from "react";

import { useMediaQuery } from "react-responsive";

import styles from "../styles/Objectives.module.css";

import { objectives } from "../app/objectives";

export const Objectives = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className={styles.objectiveList}>
      {objectives &&
        objectives.map((objective) => {
          return (
            <div
              key={Math.random()}
              className={styles.objective}
              style={{
                backgroundImage: isTabletOrMobile
                  ? objective.imgMobile
                  : objective.img,
                height: isTabletOrMobile ? "80vh" : "60vh",
              }}
            >
              <div className={styles.name}>{objective.name}</div>
              <div className={styles.desc}>{objective.desc}</div>
            </div>
          );
        })}
    </div>
  );
};

export default Objectives;
