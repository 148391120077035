import React from "react";
import { v4 as uuid } from "uuid";
import { sanFernandoInfo, makilingInterArtsInfo } from "../app/presskitInfo";
import styles from "../styles/Presskit.module.css";
import sanFernandoBannerPresskit from "../assets/San_Fernando_Banner_Presskit.webp";

export const Presskit = () => {
  return (
    <main>
      <div className={styles.container}>
        <div className={styles.containerWithTitle}>
          <h2>{sanFernandoInfo.title} Press Kit</h2>
          <div className={styles.presskit}>
            <div className={styles.navigation}>
              <img
                className={styles.banner}
                src={`${sanFernandoBannerPresskit}`}
              ></img>
              <nav>
                <ul>
                  <li>
                    <a href="#factsheet">Factsheet</a>
                  </li>
                  <li>
                    <a href="#description">Description</a>
                  </li>
                  <li>
                    <a href="#features">Features</a>
                  </li>
                  <li>
                    <a href="#videos">Videos</a>
                  </li>
                  <li>
                    <a href="#images">Images</a>
                  </li>
                  <li>
                    <a href="#about">About Studio</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={styles.content}>
              <div className={styles.row}>
                <div className={styles.details}>
                  <h3 id="factsheet">Factsheet</h3>
                  <div>
                    <h4>Developer:</h4>
                    {sanFernandoInfo.developer}
                  </div>
                  <div>
                    <h4>Platforms:</h4>
                    {sanFernandoInfo.platforms}
                  </div>
                  <div>
                    <h4>Website:</h4>
                    {sanFernandoInfo.website}
                  </div>
                  <div>
                    <h4>Social:</h4>
                    <a
                      href={sanFernandoInfo.socials.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {sanFernandoInfo.socials.twitter}
                    </a>
                    <br />
                    <a
                      href={sanFernandoInfo.socials.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {sanFernandoInfo.socials.facebook}
                    </a>
                  </div>
                </div>
                <div className={styles.details}>
                  <h3 id="description">Description</h3>
                  <div>{sanFernandoInfo.description}</div>
                  <h3 id="features">Features</h3>
                  <div>
                    {sanFernandoInfo.features.map((features) => {
                      return <li key={uuid()}>{features}</li>;
                    })}
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <h3 id="videos">Videos</h3>
                <div className={styles.assets}>
                  {sanFernandoInfo.videos.map((video) => {
                    return (
                      <iframe height="100%" key={uuid()} src={video}></iframe>
                    );
                  })}
                </div>
              </div>
              <div className={styles.row}>
                <h3 id="images">Images</h3>
                <div className={styles.assets}>
                  {sanFernandoInfo.images.map((image) => {
                    return (
                      <a
                        key={uuid()}
                        href={`presskit_images/${image}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={`presskit_images/${image}`}></img>
                      </a>
                    );
                  })}
                </div>
                <span>
                  There are far more images available for San Fernando, but
                  these are the ones we felt would be most useful to you. If you
                  have specific requests, please do contact the developer!
                </span>
              </div>
              <div className={styles.row}>
                <div className={styles.details}>
                  <h3 id="about">About {makilingInterArtsInfo.studio}</h3>
                  {makilingInterArtsInfo.studioAbout}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.details}>
                  <h3 id="contact">Contact</h3>
                  <h4>Inquiries</h4>

                  <a href={`mailto:${makilingInterArtsInfo.inquiries}`}>
                    {makilingInterArtsInfo.inquiries}
                  </a>
                  <h4>Twitter</h4>
                  <a href={makilingInterArtsInfo.twitter}>
                    {makilingInterArtsInfo.twitter}
                  </a>
                  <h4>Facebook</h4>
                  <a href={makilingInterArtsInfo.facebook}>
                    {makilingInterArtsInfo.facebook}
                  </a>
                  <h4>Web</h4>
                  <a href={makilingInterArtsInfo.website}>
                    {makilingInterArtsInfo.website}
                  </a>
                </div>
              </div>
              <div className={styles.row}>
                Inspired by<a href="https://dopresskit.com/">presskit()</a>by
                Rami Ismail(Vlambeer)
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Presskit;
