export const sanFernandoInfo = {
  title: "San Fernando",
  developer: "Makiling InterArts",
  basedIn: "General Trias",
  website: "makilinginteractivearts.com",
  platforms: "PC",
  socials: {
    twitter: "https://twitter.com/SanFernandoGame",
    facebook: "https://www.facebook.com/sanfernandogame",
  },
  description:
    "San Fernando is a choice-driven narrative game where the player steps into the shoes of an anthropologist studying and documenting the culture of this titular town from the Philippines before it completely disappears. In his journey, he learns more than he set out to, forms deep relationships with the townsfolk, and comes across a revelation that derails his scholarly pursuits. It features the idyllic rural landscapes by the Philippines’ first National Artist: Fernando Amorsolo.",
  features: [
    "A gripping story about culture, love, community, and conquest is at the center of this game",
    "Realistic simulations of a rural community and how they perceive the player. There will be varying character reactions based on the player’s dialogue choices throughout the game",
    "The paintings of the Philippines’ first National Artist, brought to life through animation, set the stage for the titular town and its story",
    "An opportunity to learn about facets of Filipino culture as reflected in this game",
    "An original soundtrack heavily inspired by Filipino folk music",
  ],
  videos: ["https://www.youtube.com/embed/h3qPw_YaLoI"],
  images: [
    "icon.jpg",
    "logo.png",
    "San_Fernando_logo.png",
    "image-1.png",
    "image-2.png",
    "image-3.png",
    "image-4.png",
    "image-5.png",
  ],
};

export const makilingInterArtsInfo = {
  studio: "Makiling InterArts",
  studioAbout:
    "Makiling InterArts is an indie game development group from the Philippines, made up of Kate Clarise de Leon, Matt Limbaga, Manuel Joseph Urbano, and Jeremiah Reyna. We aim to explore the potential of games as a storytelling medium. Our goal is to create games that tell stories from marginalized perspectives, contribute to the industry, and most of all, are always fun to play.",
  inquiries: "makilinginteractivearts@gmail.com",
  twitter: "https://twitter.com/MakilingIA",
  facebook: "https://www.facebook.com/makilinginterarts",
  website: "https://makilinginteractivearts.com/",
};
