import React from "react";
import style from "../styles/Icons.module.css";

import twitter from "../assets/icons/twitter.png";
import facebook from "../assets/icons/facebook.png";
import steam from "../assets/icons/steam.png";
import close from "../assets/icons/close.png";
import hamburger from "../assets/icons/hamburger.png";

const MIATwitter = "https://twitter.com/SanFernandoGame";
const MIAFacebook = "https://www.facebook.com/sanfernandogame";
const MIASteam =
  "https://store.steampowered.com/app/2308810?utm_source=website";

export const Icon = ({ name, label, onClick, retainColor }) => {
  let icon;
  let context = "";
  const retainColorStyle = {
    filter: "none",
  };

  if (name) {
    switch (name) {
      case "twitter":
        context = MIATwitter;
        icon = (
          <a href={MIATwitter} target={MIATwitter}>
            <img
              style={retainColor ? retainColorStyle : {}}
              className={style.icon}
              src={twitter}
            />
          </a>
        );
        break;
      case "facebook":
        context = MIAFacebook;
        icon = (
          <a href={MIAFacebook} target={MIAFacebook}>
            <img
              style={retainColor ? retainColorStyle : {}}
              className={style.icon}
              src={facebook}
            />
          </a>
        );
        break;
      case "steam":
        context = MIASteam;
        icon = (
          <a href={MIASteam} target={MIASteam}>
            <img
              style={retainColor ? retainColorStyle : {}}
              className={style.icon}
              src={steam}
            />
          </a>
        );
        break;
      case "hamburger":
        icon = (
          <a onClick={onClick}>
            <img
              style={retainColor ? retainColorStyle : {}}
              className={style.icon}
              src={hamburger}
            />
          </a>
        );
        break;
      case "close":
        icon = (
          <a onClick={onClick}>
            <img
              style={retainColor ? retainColorStyle : {}}
              className={style.icon}
              src={close}
            />
          </a>
        );
        break;
      default:
        return <></>;
    }
    if (label) {
      return (
        <a
          className={style.a}
          href={context ? context : ""}
          target={context ? context : ""}
        >
          <div className={style.button}>
            {icon.props.children}
            {label}
          </div>
        </a>
      );
    } else {
      return icon;
    }
  }
};
