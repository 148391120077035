import React from "react";

// Components
import { NavBar } from "../components/NavBar.js";

import { Routes, Route } from "react-router-dom";

// Pages
import { Home } from "../pages/Home.js";
import { Presskit } from "../pages/Presskit.js";

function MakilingInterartsWeb() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/presskit" element={<Presskit />} />
        <Route />
      </Routes>
    </>
  );
}

export default MakilingInterartsWeb;
