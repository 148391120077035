import React, { useState } from "react";
import styles from "../styles/NavBar.module.css";
import makilingInterartsLogo from "../assets/logo.webp";
import { Icon } from "./Icons";
import { NavLink } from "react-router-dom";

export const NavBar = () => {
  const [isMenu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!isMenu);
  };

  return (
    <>
      {!isMenu && <div className={styles.blackTopGradient}></div>}
      <nav className={styles.navigationBar}>
        <div className={styles.left}>
          <img className={styles.logo} src={makilingInterartsLogo} />
          <span>Makiling Interarts</span>
        </div>
        <div className={styles.middle}>
          <ul>
            <li>
              <NavLink to="/">
                <span>San Fernando</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="presskit">
                <span>Presskit</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.right}>
          <Icon name="twitter" />
          <Icon name="facebook" />
          <Icon name="steam" label="ADD TO WISHLIST" />
        </div>
      </nav>
      <div>
        {!isMenu && (
          <div className={styles.navigationBarMobile}>
            <div className={styles.leftMobile}>
              <img className={styles.logo} src={makilingInterartsLogo} />
              <span>Makiling Interarts</span>
            </div>
            <div className={styles.rightMobile}>
              <Icon name="hamburger" onClick={toggleMenu} />
            </div>
          </div>
        )}
        {isMenu && (
          <>
            <div
              className={`${styles.navigationBarMobile} ${styles.navigationBarMobileMenuOpen}`}
            >
              <div className={styles.leftMobile}>
                <img className={styles.logo} src={makilingInterartsLogo} />
                <span>Makiling Interarts</span>
              </div>
              <div className={styles.rightMobile}>
                <Icon name="close" onClick={toggleMenu} />
              </div>
            </div>
            <div className={styles.mobileMenuList}>
              <ul>
                <li onClick={toggleMenu}>
                  <NavLink to="/">
                    <span>San Fernando</span>
                  </NavLink>
                </li>

                <li onClick={toggleMenu}>
                  <NavLink to="presskit">
                    <span>Presskit</span>
                  </NavLink>
                </li>
                <li className={styles.mobileMenuListSocials}>
                  <Icon
                    name="twitter"
                    retainColor={true}
                    onClick={toggleMenu}
                  />
                  <Icon
                    name="facebook"
                    retainColor={true}
                    onClick={toggleMenu}
                  />
                  <Icon name="steam" retainColor={true} onClick={toggleMenu} />
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};
