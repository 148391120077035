import departureOfFishermenWeb from "../assets/DepartureOfFishermenWeb.webp";
import departureOfFishermenMobile from "../assets/DepartureOfFishermenMobile.webp";

import tiniklingWeb from "../assets/Tinikling.webp";
import tiniklingMobile from "../assets/TiniklingMobile.webp";

import nipaHutWeb from "../assets/NipaHutWeb.webp";
import nipaHutMobile from "../assets/NipaHutMobile.webp";

import mayonWeb from "../assets/MayonWeb.webp";
import mayonMobile from "../assets/MayonMobile.webp";

import arayaWeb from "../assets/ArayaWeb.webp";
import arayaMobile from "../assets/ArayaMobile.webp";

// Equivalent to brightness(80%)
const filter = "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2))";

export const objectives = [
  {
    name: "Explore a community in danger",
    desc: "San Fernando is a choice-driven narrative game. Step into the shoes of an anthropologist studying and documenting the culture of this titular town from the Philippines before it completely disappears. Learn more than you set out to learn, form deep relationships with the townsfolk, and come across revelations that derail your scholarly pursuits. How much of this town’s secrets will you discover?",
    img: `${filter},url(${departureOfFishermenWeb})`,
    imgMobile: `${filter},url(${departureOfFishermenMobile})`,
  },
  {
    name: "Keep an endangered culture alive",
    desc: "A good researcher takes note of everything crucial to their study. In San Fernando, one must determine what details are essential and write them down in a journal. Collate pieces of San Fernando culture and lifestyle through research notes. What do they eat? What music do they play? How do they express their love? You gain these notes when relevant information is revealed to you by the townsfolk. Gather up all the information you can to create a full report!",
    img: `${filter},url(${arayaWeb})`,
    imgMobile: `${filter},url(${arayaMobile})`,
  },
  {
    name: "Be a part of the town",
    desc: "Observing and participating are the anthropologist’s best methods in the field. You need to gain your subject’s trust before they can be interviewed for information. Become part of San Fernando by forming and nurturing relationships with the townsfolk. The people of San Fernando are always changing their impression of you and the same choice may have different outcomes depending on how they see you.",
    img: `${filter},url(${nipaHutWeb})`,
    imgMobile: `${filter},url(${nipaHutMobile})`,
  },
  {
    name: "An artist’s reality",
    desc: "This game features paintings by the Grand Old Man of Philippine Art, the country’s first National Artist: Fernando Amorsolo. Fernando Amorsolo is known for his vibrant and idyllic rural landscapes, often depicting Filipino people and scenes of everyday life. San Fernando allows players to immerse themselves within a world rendered by a master’s imagination and his skillful hand, brought further to life with modern technology.",
    img: `${filter},url(${mayonWeb})`,
    imgMobile: `${filter},url(${mayonMobile})`,
  },
  {
    name: "Fulfill your destiny",
    desc: "What’s so important about this town? What kind of people live here? How do they sustain themselves without modern practices? And more importantly, are there more secrets yet to be uncovered? You have been given the chance of a lifetime: to be the first to answer these burning questions.",
    img: `${filter},url(${tiniklingWeb})`,
    imgMobile: `${filter},url(${tiniklingMobile})`,
  },
];

export default objectives;
